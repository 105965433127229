<template>
    <div class="container">
      <!-- Table -->
      <div class="row justify-content-center">
        <div class="col">
            <el-form ref="form" :model="model" :show-message="false" label-width="auto" label-position="top" v-loading="loading">
                <div class="row">
                    <div class="col-md-9">
                        <div class="row">
                            <div class="col-md-6">
                            <label class="form-label required">专馆名称</label>
                            <el-form-item prop="store_name" :rules="[{required:true}]">
                                <el-input v-model="model.store_name" placeholder="填写专馆名称"></el-input>
                            </el-form-item>
                            </div>
                            <div class="col-md-6">
                            <label class="form-label required">经营单位</label>
                            <el-form-item prop="company_name" :rules="[{required:true}]">
                                <el-input v-model="model.company_name" placeholder="填写公司名称"></el-input>
                            </el-form-item>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3">
                            <label class="form-label required">联系人</label>
                            <el-form-item prop="contact_name" :rules="[{required:true}]">
                                <el-autocomplete v-model="model.contact_name" :fetch-suggestions="queryCN"></el-autocomplete>
                            </el-form-item>
                            </div>
                            <div class="col-md-3">
                            <label class="form-label required">联系电话</label>
                            <el-form-item prop="contact_phone" :rules="[{required:true}]">
                                <el-autocomplete type="number" v-model="model.contact_phone" :fetch-suggestions="queryCP"></el-autocomplete>
                            </el-form-item>
                            </div>
                            <div class="col-md-6">
                            <label class="form-label">地址</label>
                            <el-form-item prop="address" :rules="[{required:true}]">
                                <el-autocomplete v-model="model.address" placeholder="填写单位地址" :fetch-suggestions="queryAD"></el-autocomplete>
                            </el-form-item>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                    <label class="form-label required">入口二维码</label>
                    <el-form-item prop="qrcode" :rules="[{required:true}]">
                        <el-upload
                            class="image-uploader"
                            name="image"
                            action="/backend/api/uploadimage"
                            :with-credentials="true"
                            :show-file-list="false"
                            :on-success="handleQrcodeSuccess"
                            accept="image/png, image/jpeg">
                            <img v-if="model.qrcode" :src="proxyHost + model.qrcode" class="image-upload-preview">
                            <i v-else class="el-icon-plus image-uploader-icon"></i>
                        </el-upload>
                    </el-form-item>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-9">
                        <div class="row">
                            <div class="col">
                            <label class="form-label required">介绍</label>
                            <el-form-item prop="description" :rules="[{required:true}]">
                                <el-input v-model="model.description" type="textarea" :autosize="{ minRows: 4, maxRows: 8}" placeholder="专馆文字介绍"></el-input>
                            </el-form-item>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                            <label class="form-label">备注</label>
                            <el-form-item prop="remark">
                                <el-input v-model="model.remark"></el-input>
                            </el-form-item>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                    <label class="form-label required">店铺图片</label>
                    <el-form-item prop="poster" :rules="[{required:true}]">
                        <el-upload
                            class="image-uploader"
                            name="image"
                            action="/backend/api/uploadimage"
                            :show-file-list="false"
                            :on-success="handlePosterSuccess"
                            accept="image/png, image/jpeg">
                            <img v-if="model.poster" :src="proxyHost + model.poster" class="image-upload-preview poster">
                            <i v-else class="el-icon-plus image-uploader-icon poster"></i>
                        </el-upload>
                    </el-form-item>
                    </div>
                </div>
                <div class="text-center">
                    <button type="button" class="btn btn-primary mt-4 ls-3" @click="submit">保存</button>
                    <button type="button" class="btn btn-secondary mt-4 ls-3" @click="cancel">取消</button>
                </div>
            </el-form>
        </div>
      </div>
    </div>
</template>

<script>
/* eslint-disable */ 
//import { Form, FormItem, Autocomplete} from 'element-ui';
import utils from '@/utils'
export default {
    // components: {
    //     [Form.name]: Form,
    //     [FormItem.name]: FormItem,
    //     [Autocomplete.name]: Autocomplete
    // },
    props: {
        modeldata: {
            type: Object,
            description: "the model object to update",
            default: null
        },
        list: {
            type: Array,
            description: "the saved models",
            default: null
        },
        saved: {
            type: Boolean,
            description: "indicating data saved",
            default: false
        },
        cancelled: {
            type: Boolean,
            description: "indicating wether editing is cancelled",
            default: false
        }
    },
    data() {
      return {
        proxyHost: process.env.VUE_APP_BACKEND_TARGET || 'https://reports.bozongguanggao.com',
        tmp_modeldata: this.modeldata,
        tmp_saved: this.saved,
        tmp_cancelled: this.cancelled,
        loading: false,
        model: {
            id: null,
            store_name: '', 
            company_name: '', 
            qrcode: '', 
            contact_name: '', 
            contact_phone: '',  
            address: '',
            description: '', 
            poster: '', 
            remark: ''
        }
      }
    },
    computed: {
        profiles() {
            return this.$store.state.profiles.profiles || {}
        }
    },
    watch: {
        modeldata(v) {
            // if "modeldata" changed, means another Counter is passed to be edit
            // we need to refresh the form config
            this.model = JSON.parse(JSON.stringify(v));
            this.tmp_cancelled = false;
            this.tmp_saved = false;
        },
        tmp_modeldata(v) {
            this.$emit("update:modeldata", v);
        },
        tmp_saved(v) {
            // telling parents component the data is saved
            this.$emit("update:saved", v);
        },
        tmp_cancelled(v) {
            // telling parents component user cancelled editing
            this.$emit("update:cancelled", v);
        }
    },
    methods: {
        query(field, query, callback) {
            var res = query ? this.list.filter((x)=>{ return x[field].indexOf(query) >= 0 }) : this.list;
            res = res.map((x)=>{ return { value: x[field] } })
            callback(utils.distinct(res))
        },
        queryCN(query, cb) { this.query('contact_name', query, cb); },
        queryCP(query, cb) { this.query('contact_phone', query, cb); },
        queryAD(query, cb) { this.query('address', query, cb); },
        handleQrcodeSuccess(res, file) {
            if(res.errcode != 0) {
                this.$notifyErr('图片上传失败', res.errmsg)
            }
            this.model.qrcode = res.url;
        },
        handlePosterSuccess(res, file) {
            this.model.poster = res.url;
        },
        submit() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    if(!this.model.qrcode) { alert('请上传二维码'); return false; }
                    if(!this.model.poster) { alert('请上传店铺图片'); return false; }
                    this.save();
                } else {
                    alert('请填写完整信息');
                    return false;
                }
            });
        },
        save() {
            this.loading = true;
            this.axios({
                method: this.model.id ? 'put' : 'post', // put for update, post for create
                url: this.model.id ? 'onlinestores/' + this.model.id : 'onlinestores',
                data: this.model
            }).then((res) => {
                this.loading = false;
                if (res.data.errcode == 0) {
                    this.tmp_saved = true;
                }
                else {
                    this.$notifyErr('保存失败', res.data.errmsg);
                }
            }).catch((err) => {
                if(err.response) {
                    if(err.response.data.error) this.$notifyErr('保存失败', err.response.data.error.message);
                    else this.$notifyErr('保存失败', err.response.data[0].message);
                }
                else this.$notifyErr('保存失败', 'Failed: ' + err.message);
                this.loading = false;
            })
        },
        cancel() {
            this.tmp_cancelled = true
        }
    },
    mounted() {
        this.tmp_cancelled = false;
        this.tmp_saved = false;
        if(this.tmp_modeldata)
            this.model = JSON.parse(JSON.stringify(this.tmp_modeldata));
        else
            this.model.company_name = this.profiles.company;
    }
};
</script>
<style>
    .el-select, .el-autocomplete {
        width: 100%;
    }
    label.required::before {
        content: '*';
        color: red;
        position: absolute;
        top: 0;
        left: 6px;
        font-weight: 300;
        font-size: smaller;
    }
    .image-uploader .el-upload {
        border: 1px dashed #8c939d;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .image-uploader .el-upload:hover {
        border-color: #2dce89;
    }
    .image-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 130px;
        height: 130px;
        line-height: 130px;
        text-align: center;
    }
    .image-uploader-icon.poster {
        height: 184px;
        line-height: 184px;
    }
    .avatar-uploader .el-upload:hover .avatar-uploader-icon {
        color: #2dce89;
    }
    .image-upload-preview {
        width: 100%;
        display: block;
    }
</style>